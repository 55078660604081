
function mapDashboardDataFromBackend(data){
    return {
        cards: mapCardsFromBackend(data.cards),
        sentimentDataPoints: mapSentimentDataPointsFromBackend(data.sentiment_per_month),
        experimentalScoresDataPoints: mapExperimentalScoresDataPointsFromBackend(data.average_scores_per_experimental_category)
    }
}
function mapCardsFromBackend(data) {
    return [
            {
                title: 'Total Feedback',
                titleValue: data.card_1.total_feedback,
                subtitle: 'New feedback this week',
                subtitleValue: data.card_1.new_feedback,
                positiveTrend: data.card_1.positive_trend,
            },
            {
                title: 'Derived CSAT',
                titleValue: data.card_2.derived_csat,
                subtitle: 'derived CSAT last 7 days',
                subtitleValue: data.card_2.last_week_csat,
                positiveTrend: data.card_2.positive_trend,
            },
             {
                title: 'Total Issues This Week',
                titleValue: data.card_3.customer_with_issues_last_week,
                subtitle: 'average issues per week',
                subtitleValue: data.card_3.average_customers_with_issues_per_week,
                positiveTrend: data.card_3.positive_trend,

            }
        ]
    };

    function mapSentimentDataPointsFromBackend(data) {
        return data.map((i)=>{return {
            'month':i.month,'totalPositive':i.total_positive,
            'totalNeutral':i.total_neutral,
            'totalNegative':i.total_negative}})
    }

    function mapExperimentalScoresDataPointsFromBackend(data) {
        return {
            'averageCustomerServiceIssuesScore': data.average_customer_service_issues_score,
            'averageMissingFeaturesScore': data.average_missing_features_score,
            'averageProductDissatisfactionScore': data.average_product_dissatisfaction_score,
            'averagePricingIssuesScore': data.average_pricing_issues_score,
            'averageComplexityScore': data.average_complexity_score
        }
    }

export {
    mapDashboardDataFromBackend
}