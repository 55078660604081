<template>
    <div class="sources-container">
        <Breadcrumb>
            <template #title>
                <h1>Sources </h1>
            </template>
        </Breadcrumb>
        <div class="sources-content card-with-shadow mb-8">
            <h2 class="mb-4">Add sources</h2>
            <SourceRow source="CSV" description="Import feedback from a CSV file" @addSource="onAddSource" />
            <SourceRow source="Google Play Reviews" description="Import feedback from Google Play Store" @addSource="onAddSource" />
            <SourceRow source="Zoho" description="Import feedback from Zoho" />
            <SourceRow source="Intercom" description="Import feedback from Intercom" />
            <SourceRow source="Zendesk" description="Import feedback from Zendesk" />

        </div>
        <div class="existing-sources-container card-with-shadow">
            <h2 class="mb-4"> Your sources</h2>
            <div v-for="connector in connectors" :key="connector.id">

                <div class="card-with-shadow flex justify-between mb-4">
                    <div class="flex items-center gap-4">
                        <div class="h-12 w-12">
                            <img src="@/assets/google-play-logo.png" alt="csv" />
                        </div>
                        <div>
                            {{ connector.name }}
                        </div>
                        <div class="underline">
                            {{ connector.app_id }}
                        </div>
                    </div>
                    <div class="flex items-center gap-2">
                        <div>
                            <span>
                                Last synced at:
                            </span>
                            <span>
                                {{ getFormattedDate(connector.last_sync) }}
                            </span>
                        </div>
                        <div>
                            <FontAwesomeIcon :icon="['fas', 'pencil']" :size="size" fixed-width @click="onEditConnector(connector)" />
                        </div>
                        <div>
                            <FontAwesomeIcon :icon="['fas', 'x']" :size="size" fixed-width @click="onDeleteConnector(connector)" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <CSVModal v-model="csvModalVisible" />
        <GooglePlayReviewsModal v-model="googlePlayReviewsVisible" />

    </div>
</template>

<script setup>
    import { onMounted, ref } from 'vue';
    import Breadcrumb from '../components/Common/Breadcrumb.vue';
    import SourceRow from '../components/Sources/SourceRow.vue';
    import CSVModal from '../components/Sources/modals/CSVModal.vue';
    import GooglePlayReviewsModal from '../components/Sources/modals/GooglePlayReviewsModal.vue';
    import { connectorsService } from '@/services/connectorsService';
    import { useProjectSlug } from '@/composables/useProjectSlug';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { getFormattedDate } from '../utils/utils';
    const { projectSlug } = useProjectSlug();
    const connectors = ref(null)
    onMounted(async () => {
        connectors.value = await connectorsService.getAllConnectors(projectSlug.value)
    })
    function onAddSource(source) {
        if (source === 'CSV') {
            csvModalVisible.value = true;
        } else if (source === 'Google Play Reviews') {
            googlePlayReviewsVisible.value = true;
        }
    }
    function onEditConnector(connector) {
        alert("Edit connector coming soon. Now contact your administrator.")
    }
    function onDeleteConnector(connector) {
        alert("Delete connector coming soon. Now contact your administrator.")
    }
    const csvModalVisible = ref(false)
    const googlePlayReviewsVisible = ref(false)

</script>

<style scoped lang="scss">
    .card-with-shadow {
        padding: 1rem;
    }

</style>