import { createWebHashHistory, createRouter } from 'vue-router'
import { useAuthStore } from '@/stores/auth';
import { useProjectSlug } from '@/composables/useProjectSlug';
import Login from '../pages/Login.vue'
import Dashboard from '../pages/Dashboard.vue'
import FeedbackList from '../pages/FeedbackList.vue'
import SavedFeedback from '../pages/SavedFeedback.vue'
import Settings from '../pages/Settings.vue'
import Feedback from '../pages/Feedback.vue'
import Clusters from '../pages/Clusters.vue';
import Cluster from '../pages/Cluster.vue';
import Sources from '../pages/Sources.vue';
import Projects from '../pages/Projects.vue';
import PageNotFound from "../pages/PageNotFound.vue"
const routes = [
  { path: '/', redirect: '/projects' },
  {
    path: '/login',
    component: Login,
    name: "Login",
  },
  {
    path: '/:projectSlug/dashboard',
    component: Dashboard,
    name: "Dashboard",
    meta: { requiresAuth: true,requiresProject: true },
  },
  {
    path: '/projects',
    component: Projects,
    name: "Projects",
    meta: { requiresAuth: true, },
  },
  {
    path: '/:projectSlug/feedback-list',
    component: FeedbackList,
    name: "FeedbackList",
    meta: { requiresAuth: true ,requiresProject: true},
  },
  {
    path: '/:projectSlug/saved-feedback',
    component: SavedFeedback,
    name: "SavedFeedback",
    meta
    : { requiresAuth: true ,requiresProject: true},
  },
  {
    path: '/:projectSlug/sources',
    component: Sources,
    name: "Sources",
    meta: { requiresAuth: true ,requiresProject: true},
  },
  {
    path: '/settings',
    component: Settings,
    name: "Settings",
    meta: { requiresAuth: true },
  },
  {
    path: '/:projectSlug/feedback/:id',
    component: Feedback,
    name: "Feedback",
    meta: { requiresAuth: true ,requiresProject: true},
  },
  {
    path: '/:projectSlug/clusters',
    component: Clusters,
    name: "Clusters",
    meta: { requiresAuth: true ,requiresProject: true},
  },
  {
    path: '/:projectSlug/cluster/:id',
    component: Cluster,
    name: "Cluster",
    meta: { requiresAuth: true ,requiresProject: true},
  },
  { path: '/:pathMatch(.*)*', component: PageNotFound },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();

  if (to.meta.requiresAuth && !authStore.isAuthenticated) {
    await authStore.checkAuth();

    if (!authStore.isAuthenticated) {
      console.log('use not auth. redirecting to login')
      return next({ name: 'Login', query: { redirect: to.fullPath } });
    }
  }
  next();

});

router.afterEach((to) => {
  const { projectSlug } = useProjectSlug();
  // this is on purpose string undefined because we get it from the route param so it is converted to string
  if (to.meta.requiresProject && (projectSlug.value === 'undefined' || projectSlug.value === undefined)) {
    alert("Select a project first");
    router.push({ name: 'Projects', query: { redirect: to.fullPath } });
  }
});


export default router;