<template>
    <div class="back-arrow">
        <FontAwesomeIcon :icon="['fas', 'arrow-left']" :size="size" fixed-width @click="router.back()" />
    </div>
</template>

<script setup>
    import { useRouter } from "vue-router";
    const router = useRouter();
    const props = defineProps({
        size: {
            type: String,
            default: "lg"
        }
    })
</script>

<style scoped lang="scss">
    .back-arrow {
        cursor: pointer;
    }
</style>