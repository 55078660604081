import { feedbackAnalysisAPI,handleApiError } from '@/api';

export const feedbackAnalysisService = {
  async analyzeFeedback(projectSlug) {
    try {
      const params = {
        'project_slug': projectSlug
      }
      const { data } = await feedbackAnalysisAPI.analyzeFeedback(params);
      return data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

};