
function mapPaginatorFromBackend(data) {
    return {
        count: data.count,
        next: data.next,
        previous: data.previous,
    }
}

export {
    mapPaginatorFromBackend,
};