import { connectorsAPI,handleApiError } from '@/api';

export const connectorsService = {
  async syncAllSources(projectSlug) {
    try {
      const params = {
        'project_slug': projectSlug
      }
      const { data } = await connectorsAPI.syncAllSources(params);
      return data;
    } catch (error) {
      throw handleApiError(error);
    }
  },
  async getAllConnectors(params) {
    try {
      const result = await connectorsAPI.getAllConnectors(params);
      console.log("All Connectors",result.data.data)
      const connectors = result.data.data;
      return connectors
    } catch (error) {
      throw handleApiError(error);
    }
  },
  async addGooglePlayReviewStoreConnector(name,appId,projectSlug) {
    try {
      const params = {
        'name': name,
        'app_id': appId,
        'project_slug': projectSlug
    }
      const result = await connectorsAPI.addGooglePlayReviewStoreConnector(params);
      return result.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },
  async uploadCSV(file,projectSlug) {
    try {
      const result = await connectorsAPI.uploadCSV(file,projectSlug);
      return result.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },


};