import { createApp } from 'vue'
import './style.scss'
import App from './App.vue'
import router from './router'
import FontAwesomeIcon from "../fontawesome-icons";
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
import '../design-system.scss'
import { definePreset } from '@primevue/themes';
import { createPinia } from 'pinia'
import Tooltip from 'primevue/tooltip';
import ToastService from 'primevue/toastservice';
import * as Sentry from "@sentry/vue";


const customPreset = definePreset(Aura, {
    semantic: {
        colorScheme: {
            light: {
                primary: {
                    color: '#00045a',
                    inverseColor: '#ffffff',
                    hoverColor: '#081028',
                    activeColor: '#071338'
                },
                surface: {
                    0: '#ffffff',
                    50: '{zinc.50}',
                    100: '{zinc.100}',
                    200: '{zinc.200}',
                    300: '{zinc.300}',
                    400: '{zinc.400}',
                    500: '{zinc.500}',
                    600: '{zinc.600}',
                    700: '{zinc.700}',
                    800: '{zinc.800}',
                    900: '{zinc.900}',
                    950: '{zinc.950}'
                }
            },
            dark: {
                primary: {
                    color: '#ffffff',
                    inverseColor: '#3939ff',
                    hoverColor: '#b2b2b2',
                    activeColor: '#071338'
                },
                surface: {
                    0: '#ffffff',
                    50: '{slate.50}',
                    100: '{slate.100}',
                    200: '{slate.200}',
                    300: '{slate.300}',
                    400: '{slate.400}',
                    500: '{slate.500}',
                    600: '{slate.600}',
                    700: '{slate.700}',
                    800: '{slate.800}',
                    900: '{slate.900}',
                    950: '{slate.950}'
                }
            }
        }
    }
});
const pinia = createPinia()
const app = createApp(App)
Sentry.init({
    app,
    dsn: "https://ca082f36f56dc795fb6e718e385de488@o4508111156609024.ingest.de.sentry.io/4508111164407888",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  
app.component("FontAwesomeIcon", FontAwesomeIcon)
app.use(PrimeVue, {
    theme: {
        preset: customPreset,
        options: {
            darkModeSelector: '.app-dark'
        }
    }
});
app.use(router)
app.use(pinia)
app.use(ToastService);
app.directive('tooltip', Tooltip);

app.mount('#app')

