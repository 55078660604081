<template>
    <div class="line-chart-container card-with-shadow h-[380px] flex flex-col gap-2">
        <div class="text-l font-bold">
            Sentiment trends
        </div>
        <Chart type="line" :data="chartData" :options="chartOptions" class="line-chart h-[100%]" />
    </div>

</template>


<script setup>
    import { ref, onMounted } from "vue";
    import Chart from "primevue/chart";
    const props = defineProps({
        chartData: {
            type: Object,
            required: true,
        },
    })

    onMounted(() => {
        chartOptions.value = setChartOptions();
    });

    const chartOptions = ref();
    function setChartOptions() {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--p-text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--p-text-muted-color');
        return {
            stacked: false,
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        display: false // Removes grid lines on x-axis
                    }
                },
                y: {
                    type: 'linear',
                    display: true,
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        display: false // Removes grid lines on y-axis
                    }
                },
            },
            elements: {
                line: {
                    tension: 0.4 // Smooths out line edges
                }
            }
        };
    }
</script>
