import { handleApiError, projectAPI } from '../api';
export const projectService = {
  async getProjects() {
    try{
        const result =await projectAPI.getProjects(); 
        const projects = result.data.data;
        return projects
    }catch(error){
        throw handleApiError(error)
    }
  },
 
};