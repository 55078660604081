import api from '../api';
import { authAPI } from '../api';
export const authService = {
  login(credentials) {
    return authAPI.login(credentials);
  },
  logout() {
    return authAPI.logout();
  },
  updateUserSettings(settings) {
    return authAPI.updateUserSettings(settings)
  },
};