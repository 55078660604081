import { computed, reactive, readonly } from 'vue';

const layoutConfig = reactive({
    darkTheme: false,
});



export function useDarkMode() {
    const enableDarkMode = () => {  
        const currentTheme = document.documentElement.getAttribute('data-theme');
            document.documentElement.setAttribute('data-theme', 'dark');
            layoutConfig.darkTheme = true;
          
        if (!document.startViewTransition) {
            executeDarkModeToggle();
            return;
        }

        document.startViewTransition(() => executeDarkModeToggle(event));
    }
    const disableDarkMode = () => {
        const currentTheme = document.documentElement.getAttribute('data-theme');
            document.documentElement.setAttribute('data-theme', 'light');
            layoutConfig.darkTheme = false;
          
        if (!document.startViewTransition) {
            executeDarkModeToggle();
            return;
        }

        document.startViewTransition(() => executeDarkModeToggle(event));
    }

    const executeDarkModeToggle = () => {
        document.documentElement.classList.toggle('app-dark');
    };



    return { layoutConfig: readonly(layoutConfig), enableDarkMode,disableDarkMode };
}