<template>
    <nav class="side-navbar">
        <div class="logo" @click="router.push(`/${projectSlug}/dashboard`)">
            <img src="@/assets/logo-blue-bg.svg" alt="Logo" />
        </div>
        <ul class="nav-icons">
            <li @click="router.push(`/projects`)" :class="currentPath === '/projects' ? 'selected' : ''">
                <FontAwesomeIcon :icon="['fas', 'house']" fixed-width />
            </li>
            <li @click="router.push(`/${projectSlug}/dashboard`)" :class="currentPath === `/${projectSlug}/dashboard` ? 'selected' : ''">
                <FontAwesomeIcon :icon="['fas', 'table-columns']" fixed-width />
            </li>
            <li @click="router.push(`/${projectSlug}/saved-feedback`)" :class="currentPath === `/${projectSlug}/saved-feedback` ? 'selected' : ''">
                <FontAwesomeIcon :icon="['fas', 'bookmark']" fixed-width />
            </li>
            <li @click="router.push(`/${projectSlug}/feedback-list`)" :class="currentPath === `/${projectSlug}/feedback-list` ? 'selected' : ''">
                <FontAwesomeIcon :icon="['fas', 'layer-group']" fixed-width />
            </li>
            <li @click="router.push(`/${projectSlug}/clusters`)" :class="currentPath === `/${projectSlug}/clusters` ? 'selected' : ''">
                <FontAwesomeIcon :icon="['fas', 'object-group']" fixed-width />
            </li>
            <li @click="router.push(`/${projectSlug}/sources`)" :class="currentPath === `/${projectSlug}/sources` ? 'selected' : ''">
                <FontAwesomeIcon :icon="['fas', 'sitemap']" fixed-width />
            </li>
            <li @click="router.push(`/settings`)" :class="currentPath === `/${projectSlug}/settings` ? 'selected' : ''">
                <FontAwesomeIcon :icon="['fas', 'cog']" fixed-width />
            </li>
        </ul>
    </nav>
</template>

<script setup>
    import { computed, watch } from 'vue'
    import { useRouter } from "vue-router";
    import { useProjectSlug } from '../../composables/useProjectSlug';
    import { useRoute } from 'vue-router';
    const route = useRoute();
    const { projectSlug } = useProjectSlug();
    watch(route, () => {
        console.log("ROUTE", route)
        console.log("SLUG", projectSlug.value)
    })
    const router = useRouter();
    let currentPath = computed(() => router.currentRoute.value.path);
</script>
<style scoped lang="scss">
    .side-navbar {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: var(--secondary-background-color);
        height: 100%;
        width: 88px;
        padding: 20px 0;
        border-right: 1px solid var(--border-color);

        .logo {
            margin-bottom: 20px;
            cursor: pointer;

            img {
                width: 36px;
                height: 36px;
            }
        }

        .nav-icons {
            list-style-type: none;
            width: 100%;
            display: flex;
            flex-direction: column;


            li {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0;
                width: 100%;
                padding: 24px 0;
                cursor: pointer;
                color: var(--icons-color)
            }

            .selected {
                color: var(--primary-color);
                border-left: 4px solid var(--primary-color);
            }
        }
    }
</style>