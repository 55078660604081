// stores/auth.js
import { defineStore } from 'pinia';
import { authAPI } from '@/api';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: null,
    isAuthenticated: false,
  }),
  actions: {
    // we need to make a get request to get the csrf token
    async getCSRFToken() {
      const { data } = await authAPI.getCSRFToken();
    },
    async login(credentials) {
      const { data } = await authAPI.login(credentials);
      this.user = data.user;
      this.isAuthenticated = true;
      localStorage.setItem('auth', JSON.stringify({
        user: data.user,
        isAuthenticated: true
      }))

    },
    async logout() {
      await authAPI.logout();
      this.user = null;
      this.isAuthenticated = false;
      localStorage.removeItem('auth');
    },
    async checkAuth() {
      try {
        const { data } = await authAPI.checkAuth();
        this.user = data.user;
        this.isAuthenticated = true;
        return true;
      } catch (error) {
        this.user = null;
        this.isAuthenticated = false;
        return false;
      }
    },
  },
});