<template>
    <div class="experimental-analysis card-with-shadow">
        <h3>Experimental Analysis</h3>
        <div class="experimental-analysis-details">
            <div class="infographic">
                <ExperimentalAnalysisRow title="Customer Service Issues" :rating="customerServiceIssuesScore" />
                <ExperimentalAnalysisRow title="Missing Features" :rating="missingFeaturesScore" />
                <ExperimentalAnalysisRow title="Product Dissatisfaction" :rating="productDissatisfactionScore" />
                <ExperimentalAnalysisRow title="Pricing Issues" :rating="pricingIssuesScore" />
                <ExperimentalAnalysisRow title="Complexity" :rating="complexityScore" />
            </div>
            <div class="chart">
                <Chart type="radar" :data="chartData" :options="chartOptions" />
            </div>
        </div>
    </div>
</template>

<script setup>
    import { onMounted, ref } from 'vue';
    import ExperimentalAnalysisRow from './ExperimentalAnalysisRow.vue';
    import Chart from 'primevue/chart';
    const props = defineProps({
        customerServiceIssuesScore: {
            type: Number,
            required: true,
        },
        missingFeaturesScore: {
            type: Number,
            required: true,
        },
        productDissatisfactionScore: {
            type: Number,
            required: true,
        },
        pricingIssuesScore: {
            type: Number,
            required: true,
        },
        complexityScore: {
            type: Number,
            required: true,
        },
    })
    const chartData = ref();
    const chartOptions = ref();
    onMounted(() => {
        chartData.value = setChartData();
        chartOptions.value = setChartOptions();
    })
    const setChartData = () => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--p-text-color');

        return {
            labels: ['Customer Service', 'Missing Features', 'Product Dissatisfaction', 'Pricing', 'Complexity'],
            datasets: [
                {
                    backgroundColor: '#10376580',
                    borderColor: '#103765',
                    pointRadius: 0,
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgb(255, 99, 132)',
                    data: [props.customerServiceIssuesScore * 20, props.missingFeaturesScore * 20, props.productDissatisfactionScore * 20, props.pricingIssuesScore * 20, props.complexityScore * 20],
                    clip: { left: 5, top: 100, right: -2, bottom: 0 }
                },
            ]
        };
    };
    const setChartOptions = () => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--p-text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--p-text-muted-color');

        return {
            maintainAspectRatio: false,  // Allow chart to expand fully in the available space
            plugins: {
                legend: {
                    display: false,
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                r: {
                    grid: {
                        display: true,
                    },
                    angleLines: {
                        display: false
                    },
                    ticks: {
                        display: false,
                        stepSize: 20,
                    },
                    suggestedMin: 0,
                    suggestedMax: 100
                }
            }
        };
    }
</script>

<style scoped lang="scss">
    .experimental-analysis {

        h3 {
            margin-bottom: 40px;
        }

        .experimental-analysis-details {
            display: flex;
            justify-content: center;
            height: 100%;
            width: 100%;

            .infographic {
                display: flex;
                flex-direction: column;
                gap: 20px;
                width: 50%;
            }

            .chart {
                width: 50%;
            }
        }
    }
</style>