<template>
    <div class="radar-chart-container card-with-shadow p-20 h-[380px]">
        <div class="text-l font-bold">
            Complaints Chart
        </div>
        <div class="flex justify-center">

            <Chart type="radar" :data="chartData" :options="chartOptions" class="radar-chart h-[320px]" />
        </div>
    </div>

</template>


<script setup>
    import { ref, onMounted } from "vue";
    import Chart from "primevue/chart";
    const props = defineProps({
        chartData: {
            type: Object,
            required: true,
        },
    })
    const chartOptions = ref();
    onMounted(() => {
        chartOptions.value = setChartOptions();
    })

    const setChartOptions = () => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--p-text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--p-text-muted-color');

        return {
            maintainAspectRatio: true,  // Allow chart to expand fully in the available space

            plugins: {
                legend: {
                    display: false,
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                r: {
                    grid: {
                        display: true,
                    },
                    angleLines: {
                        display: false
                    },
                    ticks: {
                        display: false,
                        stepSize: 20,
                    },
                    suggestedMin: 0,
                    suggestedMax: 100
                }
            }
        };
    }
</script>

<style scoped lang="scss"></style>
