import { mapCustomerFromBackend } from "./customerMapper";
function mapFeedbackSourceFromBackend(feedbackSource) {
    return {
        origin: feedbackSource.origin,
        originalId: feedbackSource.original_id,
        version: feedbackSource.version,
        device: feedbackSource.device,
    }
}
function mapFeedbackSourceToBackend(feedbackSource) {
    return {
        origin: feedbackSource.origin,
        original_id: feedbackSource.originalId,
        version: feedbackSource.version,
        device: feedbackSource.device,
    }
}
function mapFeedbackPreviewFromBackend(feedbackPreview) {
  return {
    id: feedbackPreview.id,
    content: feedbackPreview.content,
    translatedContent: feedbackPreview.translated_content
  }
}
function mapFeedbackPreviewListFromBackend(feedbackPreviewList) {
  return feedbackPreviewList.map(feedbackPreview => mapFeedbackPreviewFromBackend(feedbackPreview));
}
function mapFeedbackSourceDataFromBackend(feedbackSourceData) {
    return {
        id: feedbackSourceData.id,
        appName: feedbackSourceData.app_name,
        appId: feedbackSourceData.app_id,
        rating: feedbackSourceData.rating,
    }
}
function mapFeedbackSourceDataToBackend(feedbackSourceData) {
    return {
        id: feedbackSourceData.id,
        app_name: feedbackSourceData.appName,
        app_id: feedbackSourceData.appId,
        rating: feedbackSourceData.rating,
    }
}
function mapFeedbackFromBackend(feedback) {
  const feedbackObject = {
    id: feedback.id,
    content: feedback.content,
    translatedContent:feedback.translated_content,
    language: feedback.language,
    country: feedback.country,
    customer: mapCustomerFromBackend(feedback.customer),
    source: mapFeedbackSourceFromBackend(feedback.source),
    feedbackAnalysis: mapFeedbackAnalysisFromBackend(feedback.feedback_analysis),
    title: feedback.title,
    sourceDataType: feedback.source_data_type,
    submittedAt: feedback.submitted_at,
    isLiked: feedback.is_liked,
    replyDate: feedback.reply_date,
    replyContent: feedback.reply_content,
    translatedReplyContent: feedback.translated_reply_content,
    feedbackType: feedback.feedback_type,
    priority: feedback.priority,
  };
  if ('source_data' in feedback && feedback.source_data){
    feedbackObject.sourceData = mapFeedbackSourceDataFromBackend(feedback.source_data)
  }

  if('cluster' in feedback && feedback.cluster){
    feedbackObject.cluster = {
      id: feedback.cluster.id,
      label: feedback.cluster.label,
      feedback: mapFeedbackPreviewListFromBackend(feedback.cluster.feedback)
    }
  }
    return feedbackObject
  }

  function mapFeedbackToBackend(feedback) {
    return{
      id: feedback.id,
      content: feedback.content,
      translated_content: feedback.translatedContent,
      language: feedback.language,
      country: feedback.country,
      customer: mapCustomerToBackend(feedback.customer),
      source: mapFeedbackSourceToBackend(feedback.source),
      feedback_analysis: mapFeedbackAnalysisToBackend(feedback.feedbackAnalysis),
      title: feedback.title,
      source_data_type: feedback.sourceDataType,
      submitted_at: feedback.submittedAt,
      is_liked: feedback.isLiked,
      reply_date: feedback.replyDate,
      reply_content: feedback.replyContent,
      translated_reply_content: feedback.translatedReplyContent,
      feedback_type: feedback.feedbackType, 
      priority: feedback.priority,
    }
}

function mapFeedbackListFromBackend(feedbackList) {
    return feedbackList.map(feedback => mapFeedbackFromBackend(feedback));
}

function mapFeedbackAnalysisFromBackend(feedbackAnalysis) {
    if (!feedbackAnalysis){
      return null
    }else{

    return { 
        id: feedbackAnalysis.id,
        tone: feedbackAnalysis.tone.tones,
        keyPhrases: feedbackAnalysis.key_phrases.map(phrase => ({
          text: phrase.text,
          sentiment: phrase.sentiment
        })),
        tags: feedbackAnalysis.tags.map(tag => tag.name),
        experimentalAnalysis: {
          customerServiceIssuesScore: feedbackAnalysis.experimental_analysis.customer_service_issues_score,
          missingFeaturesScore: feedbackAnalysis.experimental_analysis.missing_features_score,
          productDissatisfactionScore: feedbackAnalysis.experimental_analysis.product_dissatisfaction_score,
          pricingIssuesScore: feedbackAnalysis.experimental_analysis.pricing_issues_score,
          complexityScore: feedbackAnalysis.experimental_analysis.complexity_score
        },
        clusterLabel: feedbackAnalysis.cluster_label ?? null,
        churnRiskScore: feedbackAnalysis.churn_risk_score,
        sentiment: feedbackAnalysis.sentiment,
        feedbackType: feedbackAnalysis.feedback_type ?? null,
        priority: feedbackAnalysis.priority ?? null,
        derivedCsatScore: feedbackAnalysis.derived_csat_score,
        effortEstimateScore: feedbackAnalysis.effort_estimate_score,
        createdAt: feedbackAnalysis.created_at,
        updatedAt: feedbackAnalysis.updated_at,
        feedbackCategory: feedbackAnalysis.feedback_category
      }
    }
     
  }
export {
    mapFeedbackSourceFromBackend,
    mapFeedbackSourceToBackend,
    mapFeedbackSourceDataFromBackend,
    mapFeedbackSourceDataToBackend,
    mapFeedbackFromBackend,
    mapFeedbackToBackend,
    mapFeedbackListFromBackend,
    mapFeedbackAnalysisFromBackend,
};