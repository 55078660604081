import { mlcoreAPI,handleApiError } from '@/api';

export const mlcoreService = {
  async getClusters(params,projectSlug) {
    try {
      const { data } = await mlcoreAPI.getClusters(params,projectSlug);
      return data;
    } catch (error) {
      throw handleApiError(error);
    }
  },


};