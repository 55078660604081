<template>
    <div class="row-container">
        <div class="row-title">{{ title }}:</div>
        <Rating v-model="displayRating" :readonly="true">
            <template #onicon>
                <FontAwesomeIcon size="xs" :icon="['fas', 'circle']" fixed-width />
            </template>
            <template #officon>
                <FontAwesomeIcon size="xs" :icon="['far', 'circle']" fixed-width />
            </template>
        </Rating>

    </div>


</template>

<script setup>
    import Rating from 'primevue/rating';
    import { ref } from 'vue';
    const props = defineProps({
        rating: {
            type: Number,
            required: true,
        },
        title: {
            type: String,
            required: true,
        }
    })

    const displayRating = ref(props.rating);
</script>

<style scoped lang="scss">
    .row-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

    }
</style>