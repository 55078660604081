import { computed } from 'vue';
import { useRoute } from 'vue-router';

export function useProjectSlug() {
  const route = useRoute();
  const projectSlug = computed(() => route.params['projectSlug'])

  return {
    projectSlug
  };
}
