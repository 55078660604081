<template>
    <div class="feedback-container">
        <Toast />
        <Breadcrumb>
            <template #title>
                <h1>Feedback #{{ $route.params.id.toString().slice(0, 8) }} </h1><span> by {{ feedback?.customer?.username ?? feedback.customer.email }}</span>
                <FontAwesomeIcon :icon="['fas', 'arrow-right']" size="xl" class="origin-icon" @click="onOriginClick" v-if="!isLoading" />
            </template>
        </Breadcrumb>
        <div class="main-container card flex flex-col">
            <div class="top-details">
                <div class="main-card ">
                    <Skeleton v-if="isLoading" width="100%" height="300px" />
                    <div class="grid grid-cols-6 gap-4" v-else>
                        <MainFeedbackDetails class="col-span-4" :feedback="feedback" :isLiked="isLiked" @saveClicked="onSaveClick" />
                        <ClusterDetails class="col-span-2" v-if="feedback.cluster" :cluster="feedback.cluster" />
                    </div>
                </div>
            </div>
            <div class="bottom-details grid grid-cols-6 gap-4">
                <div class="col-span-4">
                    <Skeleton v-if="isLoading" width="34vw" height="400px" />
                    <ExperimentalAnalysisDetails v-else :customerServiceIssuesScore="customerServiceIssuesScore" :missingFeaturesScore="missingFeaturesScore" :productDissatisfactionScore="productDissatisfactionScore" :pricingIssuesScore="pricingIssuesScore" :complexityScore="complexityScore" />
                </div>
                <div class="col-span-2 ">
                    <Skeleton v-if="isLoading" width="38vw" height="400px" />
                    <FeedbackAnalysisDetails v-else :feedback="feedback" />
                </div>
            </div>
        </div>
    </div>

</template>

<script setup>
    import ExperimentalAnalysisDetails from '../components/Feedback/ExperimentalAnalysisDetails.vue';
    import FeedbackAnalysisDetails from '../components/Feedback/FeedbackAnalysisDetails.vue';
    import MainFeedbackDetails from '../components/Feedback/MainFeedbackDetails.vue';
    import ClusterDetails from '../components/Feedback/ClusterDetails.vue';
    import Skeleton from 'primevue/skeleton';
    import { ref, onMounted } from "vue";
    import feedbackService from '../services/feedbackService';
    import { useRoute, useRouter } from 'vue-router'
    import Breadcrumb from '../components/Common/Breadcrumb.vue';
    import Toast from 'primevue/toast';
    import { useToast } from 'primevue/usetoast';
    const toast = useToast();
    const route = useRoute()
    const router = useRouter()
    // we need to adjust it because the original is in scale 1-10
    const feedbackText = ref('')
    const feedback = ref({});
    const isLiked = ref(false);
    const isLoading = ref(true);
    const error = ref(null);
    const customerServiceIssuesScore = ref(0);
    const missingFeaturesScore = ref(0);
    const productDissatisfactionScore = ref(0);
    const pricingIssuesScore = ref(0);
    const complexityScore = ref(0);



    function onOriginClick() {
        window.open(feedback.value.source.origin, '_blank');
    }


    async function onSaveClick() {
        if (isLiked.value === true) {
            try {
                let result = await feedbackService.unlikeFeedback(route.params.id);
                isLiked.value = false
                toast.add({ severity: 'success', summary: 'Success Message', detail: 'Feedback unliked successfully', life: 2000 });
            } catch (error) {
                toast.add({ severity: 'error', summary: 'Error Message', detail: 'Error unliking feedback', life: 2000 });
            }
        } else {

            try {
                let result = await feedbackService.likeFeedback(route.params.id);
                isLiked.value = true
                toast.add({ severity: 'success', summary: 'Success Message', detail: 'Feedback liked successfully', life: 2000 });
            } catch (error) {
                ("Key phrase", keyPhrase);
                toast.add({ severity: 'error', summary: 'Error Message', detail: 'Error liking feedback', life: 2000 });

            }
        }
    }
    onMounted(async () => {
        isLoading.value = true;
        error.value = null;
        let id = route.params.id;
        try {
            let result = await feedbackService.getFeedbackItem(id);
            feedback.value = result;
            feedbackText.value = feedback.value.translatedContent;
            customerServiceIssuesScore.value = feedback.value.feedbackAnalysis.experimentalAnalysis.customerServiceIssuesScore;
            missingFeaturesScore.value = feedback.value.feedbackAnalysis.experimentalAnalysis.missingFeaturesScore;
            productDissatisfactionScore.value = feedback.value.feedbackAnalysis.experimentalAnalysis.productDissatisfactionScore;
            pricingIssuesScore.value = feedback.value.feedbackAnalysis.experimentalAnalysis.pricingIssuesScore;
            complexityScore.value = feedback.value.feedbackAnalysis.experimentalAnalysis.complexityScore;
            isLiked.value = feedback.value.isLiked;

        } catch (err) {
            toast.add({ severity: 'error', summary: 'Error Message', detail: 'Error fetching feedback. Please refresh.', life: 2000 });
            console.error("Error fetching feedback", err)
            error.value = "Failed to fetch feedback. Please try again later."
        } finally {
            isLoading.value = false;
        }
    });


</script>

<style scoped lang="scss">
    canvas {
        margin: 0 auto;
    }

    .feedback-container {
        height: 100%;

        .origin-icon {
            rotate: -45deg;
            cursor: pointer;
            color: var(--primary-color);
        }

        .top-container {
            display: flex;
            align-items: center;
            padding-bottom: 10px;


        }

        .main-container {
            display: flex;
            gap: 20px;

            .top-details {
                width: 100%;

            }

            .bottom-details {
                height: 100%;

            }
        }
    }


</style>