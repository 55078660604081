import { defineStore } from 'pinia';

export const useFeedbackStore = defineStore('feedback', {
  state: () => ({
    lastSelectedClusterLabel: null,
  }),
  actions: {
    setLastSelectedClusterLabel(label) {
      this.lastSelectedClusterLabel = label;
    },
  },
});