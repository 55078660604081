<template>
    <div class="feedback-preview-row py-4 border-solid border-b grid grid-cols-10 items-center justify-center">
        <FontAwesomeIcon class="arrow-icon col-span-1" :icon="['fas', 'chevron-right']" size="lg" />
        <div class="line-clamp-3 col-span-9 cursor-pointer" @click="onFeedbackClick()">
            {{ feedbackPreview.content }}
        </div>
    </div>
</template>

<script setup>
    import { useRouter } from 'vue-router';
    import { useProjectSlug } from '../../composables/useProjectSlug';
    const { projectSlug } = useProjectSlug();
    const router = useRouter();

    const props = defineProps({
        feedbackPreview: {
            type: Object,
            required: true,
        },
    })

    function onFeedbackClick() {
        router.push({ path: `/${projectSlug.value}/feedback/${props.feedbackPreview.id}` })
    }

</script>

<style scoped lang="scss">
    .arrow-icon {
        color: var(--primary-color)
    }
</style>