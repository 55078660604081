<template>
    <div class="clusters-container">
        <Breadcrumb>
            <template #title>
                <h1>Clusters</h1>
            </template>
        </Breadcrumb>

        <div class="clusters-chart-container">
            <div class="clusters-options" :style="style" style="position: fixed">
                <h4 class="drag-me-banner" ref="optionsEl"> You can drag me anywhere👋</h4>
                <div>
                    Here offer options like positive or negative datasets
                </div>
                <div class="filter-option">
                    <h4>
                        Sentiment:
                    </h4>
                    <Select v-model="selectedSentiment" :options="sentimentOptions" optionLabel="label" placeholder="Select a sentiment" class="w-full md:w-56" />
                </div>
                <div class="filter-option">
                    <h4>
                        Arrangement of bubbles:
                    </h4>
                    <Select v-model="selectedArrangement" :options="arrangementOptions" optionLabel="label" placeholder="Select preferred arrangement" class="w-full md:w-56" />
                </div>
                <div class="filter-option">
                    <h4>
                        Date Range:
                    </h4>
                    <Select v-model="selectedDateRange" :options="dateOptions" optionLabel="label" placeholder="Select date range" class="w-full md:w-56" />
                </div>
            </div>
            <div id="chart" class="chart"></div>
        </div>
    </div>
</template>

<script setup>
    import { onMounted } from 'vue';
    import { useDraggable } from '@vueuse/core'
    import * as d3 from 'd3';
    import Breadcrumb from '../components/Common/Breadcrumb.vue';
    import d3Tip from "d3-tip";
    import { mlcoreService } from '../services/mlcoreService';
    import Select from 'primevue/select';
    import { ref, watch } from 'vue';
    import { useRoute, useRouter } from 'vue-router'
    import { useFeedbackStore } from '../stores/feedback';
    import moment from 'moment';
    import { useProjectSlug } from '../composables/useProjectSlug';
    const { projectSlug } = useProjectSlug();
    const feedbackStore = useFeedbackStore()
    const route = useRoute()
    const router = useRouter()
    const optionsEl = ref(null)
    // `style` will be a helper computed for `left: ?px; top: ?px;`
    const { x, y, style } = useDraggable(optionsEl, {
        initialValue: { x: 120, y: 170 },
    })

    const sentimentOptions = [
        { label: 'All', value: 'all' },
        { label: 'Positive', value: 'positive' },
        { label: 'Negative', value: 'negative' },

    ];
    const selectedSentiment = ref(sentimentOptions[0]);

    const arrangementOptions = [
        { label: 'Larger Centered', value: 'larger_centered' },
        { label: 'Smaller Centered', value: 'smaller_centered' },
        { label: 'Random', value: 'random' },
    ]
    const selectedArrangement = ref(arrangementOptions[0])

    const dateOptions = [
        { label: 'All', value: 'all' },
        { label: 'Last month', value: 'last_month' },
        { label: 'Last 3 months', value: 'last_3_months' },
        { label: 'Last 6 months', value: 'last_6_months' },
    ]
    const selectedDateRange = ref(dateOptions[0])
    const items = []
    watch(selectedSentiment, () => {
        fetchClustersAndRender();
    });
    watch(selectedArrangement, () => {
        fetchClustersAndRender();
    });
    watch(selectedDateRange, () => {
        fetchClustersAndRender();
    });
    onMounted(() => {
        fetchClustersAndRender();
    });
    async function fetchClustersAndRender() {
        let clusters;
        let params = {};
        if (selectedSentiment.value.value !== 'all') {
            params['sentiment'] = selectedSentiment.value.value;
        }
        switch (selectedDateRange.value.value) {
            case 'last_month':
                params['submitted_after'] = moment().subtract(1, 'month').format('YYYY-MM-DD');
                break;
            case 'last_3_months':
                params['submitted_after'] = moment().subtract(3, 'month').format('YYYY-MM-DD');
                break;
            case 'last_6_months':
                params['submitted_after'] = moment().subtract(6, 'month').format('YYYY-MM-DD');
                break;
            default:
                break;
        }

        clusters = await mlcoreService.getClusters(params, projectSlug.value);
        items.value = clusters.map((cluster) => {
            return {
                id: cluster.id,
                name: cluster.label,
                value: cluster.feedback_count,
                sentiment: cluster.sentiment || selectedSentiment.value.value // Add sentiment to each item
            }
        });
        if (selectedArrangement.value.value == 'smaller_centered') {
            items.value.sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0))
        } else if (selectedArrangement.value.value == 'larger_centered') {
            items.value.sort((a, b) => (a.value < b.value) ? 1 : ((b.value < a.value) ? -1 : 0))
        }

        render();
    }
    function calculateMaxRadius(items, diameter) {
        // Create the root hierarchy and apply the packing layout
        const root = d3.hierarchy({ children: items })
            .sum(d => d.value);

        const pack = d3.pack().size([diameter, diameter]).padding(0);
        pack(root);

        // Extract the max radius from the nodes
        const maxRadius = d3.max(root.children, d => d.r);

        return maxRadius;
    }
    function render() {
        // limit select is the max number of bubbles to show. This will be the length of the json response
        const limitSelect = 20;
        let idx = 0;
        const limit = limitSelect
        document.querySelector('#chart').innerHTML = '';

        const values = items.map(d => d.value);
        const min = Math.min(...values);
        const max = Math.max(...values);
        const total = items.length;
        const diameter = 700;
        const maxRadius = calculateMaxRadius(items.value, diameter); const bubble = d3.pack().size([diameter, diameter]).padding(0);
        // const tip = d3Tip()
        //     .attr('class', 'd3-tip-outer')
        //     .offset([-38, 0])
        //     .html((event, d) => {
        //         const item = d;
        //         const color = getColor(root.children.indexOf(item), items.length, selectedSentiment.value.value);
        //         return `<div class="d3-tip" style="background-color: ${color};color:white">${item.data.name} (${item.data.value})</div><div class="d3-stem" style="border-color: ${color} transparent transparent transparent"></div>`;
        //     });

        const svg = d3.select('#chart').append('svg')
            .attr('viewBox', `0 0 ${diameter * 1.5} ${diameter * 1.5}`)
            .attr('class', 'chart-svg');

        const root = d3.hierarchy({ 'children': items.value }).sum(d => d.value);

        bubble(root);

        const node = svg.selectAll('.node')
            .data(root.children)
            .enter()
            .append('g')
            .attr('class', 'node')
            .attr('transform', d => `translate(${d.x * 1.5} ${d.y * 1.5})`)
            .append('g')
            .attr('class', 'graph');

        node.append('circle')
            .attr('r', d => d.r * 1.5)
            .style('fill', (d, i) => getColor(d.r, maxRadius, d))
            // .on('mouseover', function (event, d) {
            //     tip.show(event, d, this);
            // })
            // .on('mouseout', tip.hide)
            .on('click', function (event, d) {
                feedbackStore.setLastSelectedClusterLabel(d.data.name);
                ("set in store", feedbackStore.lastSelectedClusterLabel)
                router.push({ name: 'Cluster', params: { id: d.data.id } });

            });

        // node.call(tip);
        // top text
        // Append the top text (wrapped)
        node.append('text')
            .attr('y', '-1.0em')
            .style('text-anchor', 'middle')
            .style('font-size', getFontSizeForItem)
            .selectAll('tspan')
            .data(d => wrapText(getLabel(d), 22))  // Limit characters per line (e.g., 15)
            .enter()
            .append('tspan')
            .attr('x', 0)
            .attr('dy', (d, i) => i === 0 ? 0 : '1.2em') // Adjust line spacing
            .text(d => d)
            .style("fill", "#ffffff")
            .style('pointer-events', 'none');

        // Append the bottom text (adjust position dynamically)
        node.append("text")
            .attr("dy", d => {
                const lineCount = wrapText(getLabel(d), 22).length;
                return `${1.3 + lineCount * 1.2}em`; // Adjust based on number of lines in top text
            })
            .style("text-anchor", "middle")
            .style('font-size', getFontSizeForItem)
            .text(getValueText)
            .style("fill", "#ffffff")
            .style('pointer-events', 'none');


        function wrapText(text, maxChars) {
            const words = text.split(' ');
            const lines = [];
            let currentLine = [];

            words.forEach(word => {
                const newLine = [...currentLine, word].join(' ');
                if (newLine.length > maxChars) {
                    lines.push(currentLine.join(' '));
                    currentLine = [word];
                } else {
                    currentLine.push(word);
                }
            });

            if (currentLine.length) {
                lines.push(currentLine.join(' '));
            }

            return lines;
        }
        function getColor(radius, maxRadius, item) {
            const greenColors = ['#00ff0099', '#00e60099', '#00cc0099', '#00b30099', '#009900', '#007f00', '#006600', '#004d00', '#003300'];
            const redColors = ['#ffcccc99', '#ff999999', '#ff4d4d99', '#ff000099', '#e60000', '#cc0000', '#b30000', '#990000', '#800000'];

            // Choose the appropriate color list based on item's sentiment
            const colorList = item.data.sentiment === 'positive' ? greenColors : redColors;
            // Create a linear scale to map radius to color index
            const colorScale = d3.scaleLinear()
                .domain([0, maxRadius])
                .range([0, colorList.length - 1]);

            // Calculate the color index
            const colorIndex = Math.round(colorScale(radius));

            // Ensure the color index is within bounds
            return colorList[Math.min(colorList.length - 1, Math.max(0, colorIndex))];
        }
        function getValueText(item) {
            if (item.data.value < max / 3.3) {
                return '';
            }
            return item.data.value;
        }
        function truncate(label) {
            const max = 64;
            if (label.length > max) {
                label = label.slice(0, max) + '...';
            }
            return label;
        }

        function getFontSizeForItem(d) {
            return `${getFontSize(d.r)}px`;
        }

        function getFontSize(radius) {
            const minPx = 6;
            const maxPx = 16;
            const minRadius = 10;
            const maxRadius = 100;
            const fontSize = minPx + (maxPx - minPx) * (radius - minRadius) / (maxRadius - minRadius);

            // Ensure font size is within bounds
            return Math.max(minPx, Math.min(maxPx, fontSize));
        }

        function getLabel(d) {
            if (d.r < 20) {
                return truncate(d.data.name, 14);
            }
            if (d.r < 25) {
                return truncate(d.data.name, 30);
            }
            if (d.r < 40) {
                return truncate(d.data.name, 50);
            }
            if (d.r < 50) {
                return truncate(d.data.name, 65);
            }
            return d.data.name
        }

        function truncate(label, maxLength) {
            if (label.length > maxLength) {
                return label.slice(0, maxLength - 3) + '...';
            }
            return label;
        }
    }
</script>


<style scoped lang="scss">
    .clusters-chart-container {
        .clusters-options {
            width: 260px;
            background-color: var(--background-color);
            border: 1px solid var(--border-color);
            float: left;
            padding: 15px;
            margin: 0;
            position: fixed;
            display: flex;
            flex-direction: column;
            gap: 12px;
            z-index: 2;
            color: var(--text-color);

            .drag-me-banner {
                border-bottom: 1px solid var(--border-color);
                cursor: move;
            }
        }
    }

    .first-h3 {
        margin-top: 0;
    }

    legend {
        background-color: white;
        padding: 3px 8px;
        border: 1px solid #bbb;
    }

    #container {
        width: 960px;
        margin: 0 auto;
        background-color: #e0e0e0;
    }

    .bubble-label {
        text-align: center;
        line-height: 1.5;
        cursor: default;
        user-select: none;
    }

    .bubble-value {
        color: rgba(255, 255, 255, 0.8);
        font-weight: 300;
        cursor: default;
        user-select: none;

    }

    button {
        clear: left;
        float: left;
        font-size: 16px;
        margin-top: 10px;
        border-radius: 5px;
        padding: 8px 15px;
        background-color: white;
        border: 1px solid #bbb;
    }


    text {
        fill: #fff;
        transition: all 0.3s;
        text-overflow: ellipsis;
    }

    .label {
        fill: #000;
    }

    .chart {
        margin: 0 auto;
        max-width: 100%;
        max-height: 100%;
        cursor: pointer;
    }

    .chart-svg {
        width: 100%;
        height: 100%;
    }

    .node {
        cursor: default;

        @for $i from 1 through 30 {
            &:nth-child(#{$i}) .graph {
                $delay: $i * 0.033s;
                animation-delay: $delay;
            }
        }
    }

    .node circle {
        transition: transform 200ms ease-in-out;
    }

    .node:hover circle {
        transform: scale(1.05);
    }

    .graph {
        opacity: 0;
        animation-name: animateIn;
        animation-duration: 900ms;
        animation-fill-mode: forwards;
        animation-timing-function: cubic-bezier(.7, .85, .41, 1.51);
    }

    @keyframes animateIn {
        0% {
            opacity: 0;
            transform: scale(0.5) rotate(-8deg);
        }

        100% {
            opacity: 1;
            transform: scale(1) rotate(0);
        }
    }


    .d3-tip-outer {
        position: relative;
    }

    .d3-tip {
        font-family: Roboto, sans-serif;
        font-size: 18px;
        font-weight: 100;
        line-height: 1;
        padding: 16px 20px;
        color: white;
        border-radius: 6px;
    }

    .d3-stem {
        width: 0;
        height: 0;
        position: absolute;
        bottom: -45px;
        left: 55%;
        border-style: solid;
        border-width: 48px 15px 0 0;
        transform: rotate(17deg);
        transform-origin: 100% 0;
        z-index: 2;
    }

    /* Creates a small triangle extender for the tooltip */
    .d3-tip:after {
        box-sizing: border-box;
        display: inline;
        font-size: 10px;
        width: 100%;
        line-height: 1;
        color: rgba(0, 0, 0, 0.8);
        content: "\25BC";
        position: absolute;
        text-align: center;
    }

    /* Style northward tooltips differently */
    .d3-tip.n:after {
        margin: -1px 0 0 0;
        top: 100%;
        left: 0;
    }
</style>