<template>
    <div class="most-common-labels-container">
        <ul class="list-none card-with-shadow p-4 flex flex-col gap-2 w-full">
            <div class="text-xl font-semibold border-b pb-4">
                {{ title }}
            </div>
            <li v-for="(item, index) in data" :key="item.label" :class="[' rounded border border-transparent transition-all transition-duration-200']">
                <div class="flex py-2 items-center gap-4">
                    <Avatar :label="index.toString()" class="avatar" :class="sentiment.toLowerCase() == 'positive' ? 'avatar-positive' : 'avatar-negative'" />
                    <div class="flex-1 flex flex-col gap-1">
                        <span class="font-bold">{{ item.label }}</span>
                        <div class="flex items-center gap-2">
                            <i class="pi pi-tag text-sm"></i>
                            <span>{{ item.count }}</span>
                        </div>
                    </div>
                    <Chart type="line" :data="chartData" :options="chartOptions" class="trend-chart" />

                </div>
            </li>
        </ul>
    </div>


</template>

<script setup>
    import { ref, onMounted } from "vue"
    import Avatar from 'primevue/avatar';
    import Chart from 'primevue/chart';
    const props = defineProps({
        data: {
            type: Array,
            required: true,
        },
        sentiment: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        }
    })
    onMounted(() => {
        chartData.value = setChartData();
        chartOptions.value = setChartOptions();
    });

    const chartData = ref();
    const chartOptions = ref();

    const setChartData = () => {
        const documentStyle = getComputedStyle(document.documentElement);

        return {
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            datasets: [
                {
                    label: 'Dataset 1',
                    fill: false,
                    borderColor: documentStyle.getPropertyValue('--p-cyan-500'),
                    yAxisID: 'y',
                    tension: 0.4,
                    data: [65, 59, 80, 81, 56, 55, 10]
                },
            ]
        };
    };
    const setChartOptions = () => {
        return {
            plugins: {
                legend: {
                    display: false // Hide the legend
                }
            },
            scales: {
                x: {
                    display: false // Hide the x-axis (no ticks, no grid)
                },
                y: {
                    display: false // Hide the y-axis (no ticks, no grid)
                }
            },
            elements: {
                line: {
                    borderColor: '#42A5F5', // Set the line color
                    borderWidth: 2 // Set the line thickness
                },
                point: {
                    radius: 0 // Remove the points on the line
                }
            },
            maintainAspectRatio: false, // Optional, depending on your layout
        };
    }


</script>

<style scoped lang="scss">
    .most-common-labels-container {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .card-with-shadow {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        .avatar {
            width: 60px;
            height: 60px;
            min-width: 60px;
            color: white;
            font-size: 20px;
            font-weight: bold;

        }

        .avatar-positive {
            background-color: var(--green-color);
        }

        .avatar-negative {
            background-color: var(--red-color);
        }

        .trend-chart {
            height: 50px;
            max-width: 200px;
        }
    }


</style>