function mapCustomerFromBackend(customer) {
    return {
        id: customer.uuid,
        name: customer.name,
        surname: customer.surname,
        username: customer.username,
        email: customer.email,
        language: customer.language,
        country: customer.country,
        phone: customer.phone,
        address: customer.address,
    }
}

function mapCustomerToBackend(customer) {
    return {
        uuid: customer.id,
        name: customer.name,
        surname: customer.surname,
        username: customer.username,
        email: customer.email,
        language: customer.language,
        country: customer.country,
        phone: customer.phone,
        address: customer.address,
    }
}
export{
    mapCustomerFromBackend,
    mapCustomerToBackend
}

